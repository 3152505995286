<template>
  <div>
    <b-sidebar
      id="sidebar-action"
      ref="sidebarAction"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-action-header"
      right
      backdrop
      shadow
      no-header
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Set Customer's Attribute</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <b-form-radio-group v-model="isSelected" @change="setAction">
            <b-row>
              <b-col
                ><b-form-radio :value="2">Unconditional</b-form-radio></b-col
              >
              <b-col><b-form-radio :value="1">Custom</b-form-radio></b-col>
            </b-row>
          </b-form-radio-group>
        </div>
        <div v-if="isSelected === 1">
          <b-row class="mt-3">
            <b-col md="4">
              <label>Attribute Type <span class="text-error">*</span></label>
            </b-col>
            <b-col md="8">
              <label>Show Information <span class="text-error">*</span></label>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-for="(item, index) in form" :key="index">
            <b-col md="4">
              <InputSelectAutomation
                v-bind:options="dropdownAction"
                :value="item.automation_attribute_type_id"
                :v-model="item.automation_attribute_type_id"
                @onDataChange="selectActionId"
                :indexCondition="index"
                valueField="id"
                textField="name"
                :v="v.$each.$iter[index].automation_attribute_type_id"
                :isValidate="
                  v.$each.$iter[index].automation_attribute_type_id.$error
                "
              />
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col md="10">
                  <InputSelectAutomation
                    v-if="item.automation_attribute_type_id == 1"
                    v-bind:options="memberLevel"
                    :noPleaseSelect="noPleaseSelect"
                    :v="v.$each.$iter[index].value"
                    :isValidate="v.$each.$iter[index].value.$error"
                    :value="item.value"
                    v-model="item.value"
                    :indexCondition="index"
                    @onDataChange="selectActionValue"
                    valueField="id"
                    textField="name"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation>

                  <InputSelectAutomation
                    v-if="item.automation_attribute_type_id == 2"
                    v-bind:options="richMenuOptions"
                    :noPleaseSelect="noPleaseSelect"
                    :v="v.$each.$iter[index].value"
                    :isValidate="v.$each.$iter[index].value.$error"
                    :value="item.value"
                    v-model="item.value"
                    :indexCondition="index"
                    @onDataChange="selectActionValue"
                    valueField="id"
                    textField="name"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeCondition(index)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- <div class="mt-3">
            <button @click.prevent="addRuleAction" class="btn-add">
              <font-awesome-icon icon="plus-square" class="mr-2" />
              <span class="add-new">Create a New List</span>
            </button>
          </div> -->
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitAction"
              block
              variant="light"
              class="text-body rounded-pill btn-main"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
export default {
  components: {
    InputSelectAutomation,
  },
  props: {
    form: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
    memberLevel: { required: false },
  },
  data() {
    return {
      isShowSidebar: false,
      noPleaseSelect: true,
      isSelected: this.selected,
      findPoint: false,
      dropdownAction: [
        { id: 1, name: "Set Member Tier" },
        { id: 2, name: "Set Rich Menu" },
      ],
      richMenuOptions: [],
    };
  },
  methods: {
    fieldOptions(id) {
      console.log(id);
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    selectActionId(...value) {
      this.form[value[1]].automation_attribute_type_id = value[0];
      // this.form[value[1]].action_type_name = value[2];
      this.$nextTick();
    },
    removeCondition(index) {
      this.form.splice(index, 1);
      const findTypePoint = this.form.find((el) => el.action_type_id == 2);
      if (findTypePoint) {
        this.findPoint = true;
      } else {
        this.findPoint = false;
      }
    },
    selectActionValue(...value) {
      this.form[value[1]].value = value[0];
    },
    addRuleAction() {
      this.form.push({
        id: 0,
        marketing_automation_id: 0,
        message_segmentation_id: this.$route.params.id,
        automation_attribute_type_id: this.dropdownAction[0].id,
        is_attribute: this.isSelected,
        value: "",
      });
    },
    submitAction() {
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("submit", this.isSelected);
          this.hide();
        } else {
          this.v.$touch();
          if (this.v.$error) {
            return;
          }
          this.$emit("submit", this.isSelected);
          this.hide();
        }
      } else {
        this.$swal("Please specify privileges condition.", {
          icon: "warning",
        });
      }
    },
    setAction() {
      if (this.isSelected === 1) {
        if (this.form.length < 1) {
          this.form.push({
            id: 0,
            marketing_automation_id: 0,
            message_segmentation_id: this.$route.params.id,
            automation_attribute_type_id: this.dropdownAction[0].id,
            is_attribute: this.isSelected,
            value: "",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
</style>
